
/* Captcha Css*/

body {
    font-family: tahoma, arial;
  }
  .box {
    width: 350px;
    height: 300px;
    background: #e6e6e6;
    margin: auto;
    margin-top: 100px;
    border: 4px solid #dcafaf;
    border-radius: 15px;
    transition: all .5s ease-in-out;
  }
  
  .box-CAPTCHA, .box-done {transition: all .5s ease-in-out;height: 100%;}
  
  .CAPTCHA {
    width: 250px;
    height: 100px;
    background:linear-gradient(to left, #ececec, silver, #ececec);
    margin: 40px 10px 10px;
    color: #089;
    font-size: 50px;
    line-height: 100px;
    border: 2px solid #888;
    border-radius: 10px;
    float: left;
    pointer-events: none;
    text-align: center;
    transition: all .5s ease-in-out;
  }
  
  .reload {
    float: left;
    margin-top: 80px;
    text-align: center;
    width: 25px;
    height: 25px;
    outline: none;
    border: none;
    background: #FFF;
    cursor: pointer;
    border: 1px solid #444;
    border-radius: 3px;
    transition: all .5s ease-in-out;
  }
  
  .reload:hover {
    background: #f2f2f2;
  }
  
  .reload i {
    font-size: 20px;
    margin-left: -4px;
    font-weight: bold;
    transform: rotateY(180deg);
  }
  
  input#input {
    outline: none;
    width: 325px;
    height: 30px;
    background:#F2F2F2;
    margin: 10px 10px;
    color: #444;
    line-height: 30px;
    border: 2px solid #888;
    border-radius: 5px;
    transition: all .5s ease-in-out;
  }
  
  .submit {
    outline: none;
    border: none;
    width: 120px;
    height: 40px;
    margin: 10px 120px;
    cursor: pointer;
    background: #3792e2;
    color: #FFF;
    font-weight: bold;
    border-radius: 20px;
    border: 1px solid #444;
    transition: all .5s ease-in-out;    
  }
  
  .submit:hover {
    background: #1b7ed6;
  }
  
  .submit:active {
    background: #0d4779;
  }
  
  .box-done h1 {
      position: absolute;
      color: #444;
      margin: 110px 90px;
      font-size: 50px;
  }
  
  .box-done h1 i {
      font-size: 42px;
      background: #080;
      color: #fff;
      border-radius: 50%;
      margin-right: 10px;
      width: 45px;
      height: 45px;
      font-weight: bold;
  }
  
  .back {
    outline: none;
    border: none;
    width: 120px;
    height: 40px;
    bottom: 10px;
    margin: 220px 120px;
    cursor: pointer;
    background: #3792e2;
    color: #FFF;
    font-weight: bold;
    border-radius: 20px;
    border: 1px solid #444;
    transition: all .5s ease-in-out;    
  }
  
  .back:hover {
    background: #1b7ed6;
  }
  
  .back:active {
    background: #0d4779;
  }
  .done-sec h1 {
    position: relative;
    margin: 0;
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}
.done-sec {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    height: 100%;
    flex-wrap: wrap;
}
.done-sec a {
    background: red;
    padding: 10px 30px;
    display: block;
    width: 90%;
    text-align: center;
    color: #fff;
    border-radius: 30px;
}
.done-sec h1 svg {
  width: 50px;
  height: 50px;
  background: green;
  border-radius: 50%;
  padding: 10px;
  margin: 0 10px 0 0px;
}
.done-sec h1 svg path {
  fill: #fff;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.profile_img img {
  height: 140px;
    max-width: 140px;
    border-radius: 50%;
    overflow: hidden;
}


.cut_sub_btn button {
  background-color: #EE3124;
  color: #fff;
  border: none;
  font-size: 16px;
  font-weight: 600;
  line-height: 18px;
  font-family: 'Raleway', sans-serif;
  width: 150px;
  height: 50px;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.edit_profile button {
  background-color: #EE3124;
  color: #fff;
  border: none;
  font-size: 16px;
  font-weight: 600;
  line-height: 18px;
  font-family: 'Raleway', sans-serif;
  width: 150px;
  height: 50px;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ques-img {
  max-width: 500px;
  width: 100%;
  margin: 20px auto;
  border-radius: 10px;
  overflow: hidden;
}

.ques-img img {
  width: 100%;
  height: 280px;
  object-fit: cover;
  transition: all 1s ease-in-out;
}

.ques-img:hover img {
  transform: scale(1.10);
}

.bottom {
  text-align: center;
}

.progress-bar {
  background: #ee3124 !important;
  border-radius: 5px;
}

.ques-no {
  padding: 7px;
  width: 100%;
  max-width: 20%;
}

.bottom button {
  background-color: #EE3124;
  padding: 6px 15px;
  color: #fff;
  border-radius: 40px;
  margin: 0 10px 0 0;
  border: none;
}

progress {
  width: 100%;
  max-width: 80%;
}

.count-ques {
  width: 100%;
  max-width: 15%;
}

.progress-main {
  width: 100%;
  max-width: 85%;
}

.count-ques p {
  margin: 0;
}

.count-ques-main {
  display: flex;
  align-items: center;
}

.option-main {
  display: flex;
  margin: 0 0 20px 0;
}

.option-main .option {
  width: 100%;
  flex-basis: 100%;
  max-width: 300px;
}
.score h1 {
  font-size: 24px;
  font-weight: 400;
  color: #000;
  text-align: right;
}
button.view_btn {
  background-color: #EE3124;
  padding: 6px 15px;
  color: #fff;
  border-radius: 40px;
  margin: 0 10px 0 0;
}
.activity-sec .list-group-item a {
  background: #EE3124 !important;
  color: #fff !important;
  border: none !important;
}





/* crop image */
@import url('https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900&display=swap');

button:focus,
input:focus{
  outline: none;
  box-shadow: none;
}
a,
a:hover{
  text-decoration: none;
}

body{
  font-family: 'Roboto', sans-serif;
}

/*------------------  */
.ci-user-picture {
    min-width: 150px;
    margin-right: 16px;
}
.ci-user-picture img {
    width: 150px;
    height: 150px;
    border-radius: 50%;
}
.btn-default {
    display: inline-block;
    padding: 14px 32px;
    border: none;
    border-radius: 6px;
    font-size: 16px;
    transition: 0.5s;
    text-align: center;
    text-transform: capitalize;
}
.bg-blue {
    background-color: #006AFA;
    color: #fff !important;
}
.filepreviewprofile {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
}

/*----modal--------*/
.modal-header-bg {
    width: 100%;
    height: 12px;
    background: linear-gradient(269.44deg, #006AFA 2.3%, #00AE86 99.19%);
}
.cropImageModal .modal-dialog{
    max-width: 750px;
    width: 90%;
    margin: 0.5rem auto;
}
.up-photo-title{
    padding: 8px 15px;
    text-align: center;
}

#upload-demo{
    position: relative;
	width: 100%;
	height: 250px;
  padding-bottom:0;
}
.croppie-container h5 {
    position: absolute;
    bottom: 4px;
    text-align: center;
    left: 0;
    right: 0;
    color: #fff;
    z-index: 2;
    font-size: 15px;
}
figure figcaption {
    position: absolute;
    bottom: 0;
    color: #fff;
    width: 100%;
    padding-left: 9px;
    padding-bottom: 5px;
    text-shadow: 0 0 10px #000;
}
.croppie-container .cr-image{
    right: 0;
}
.croppie-container .cr-slider-wrap {
    width: 275px;
}
.cr-slider-wrap p{
    font-size: 13px;
    color: #8D8D94;
    text-align: left;
}
.cr-slider{
    width: 275px;
}
.upload-action-btn {
    margin-top: 60px;
    padding-top: 40px;
}

.cr-slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 18px;
    height: 18px;
    background: #006AFA;
    cursor: pointer;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.25);
}
  
.cr-slider::-moz-range-thumb {
    width: 18px;
    height: 18px;
    background: #006AFA;
    cursor: pointer;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.25);
}
.cr-slider::-ms-thumb {
    width: 18px;
    height: 18px;
    background: #006AFA;
    cursor: pointer;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.25);
}
.cr-slider::-webkit-slider-runnable-track {
    width: 275px;
    height: 2px;
    cursor: pointer;
    box-shadow: none;
    background: #E0E0E0;
    border-radius: 3px;
    border:none;
}
.cr-slider:focus::-webkit-slider-runnable-track {
    background: #E0E0E0;
}
.cr-slider::-moz-range-track {
    width: 275px;
    height: 2px;
    cursor: pointer;
    box-shadow: none;
    background: #E0E0E0;
    border-radius: 3px;
    border:none;
}
.cr-slider::-ms-track {
    width: 275px;
    height: 2px;
    cursor: pointer;
    box-shadow: none;
    background: #E0E0E0;
    border-radius: 3px;
    border:none;
    color: transparent;
}

.policy a {
  margin: 0 16px;
}

.not-click {
    pointer-events: none;
}

.anchor-color {
    color: grey !important;
}

.nav-link.active {
    background-color: #ee3124;
    color: white;
}